import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowGallery01 from '../components/organisms/officialLowGallery01'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import OfficialTopSection04 from '../components/organisms/officialTopSection04'

const Wrapper = styled.main``

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * ギャラリーページ[サービスサイトギャラリー]
 * @constructor
 */
const GalleryPage = ({ location }: IProps) => (
  <OfficialLayout location={location}>
    <SEO title="ギャラリー" />
    <Wrapper>
      <OfficialLowHeader
        label="ギャラリー"
        description="いくつかの機能について画面イメージを公開しています。"
      />
      <OfficialLowGallery01 />
      <OfficialTopSection04 />
    </Wrapper>
  </OfficialLayout>
)

export default GalleryPage

export const pageQuery = graphql`
  query GalleryPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
