import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  &.modal-wrapper {
    .modal-window {
      padding-top: 0;
      .modal-content {
        display: flex;
        position: relative;
        h4 {
          padding: 20px 0 10px;
          position: fixed;
          background: #ffffff;
          width: calc(90vw - 60px);
          max-width: 1200px;
        }
        img {
          width: 100%;
          height: 100%;
          margin: 54px 0 0;
          border: solid 2px #cccccc;
        }
      }
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
  /** ID名 */
  idName?: string
  /** ボタンラベル */
  label?: string
  /** 説明文 */
  description?: string
  /** 画像 */
  fullImg?: string
}

/**
 * ギャラリー用コンテンツ
 */
const GalleryContent = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`modal-wrapper${className}`} id={props.idName}>
      <a href="#!" className="modal-overlay">
        &nbsp;
      </a>
      <div className="modal-window">
        <div className="modal-content">
          {props.label ? <h4>{props.label}</h4> : ' '}
          {props.fullImg && (
            <img
              src={`/images/official/gallery/${props.fullImg}.png`}
              alt="キンクラ画面"
            />
          )}
          {props.description && <p>{props.description}</p>}
        </div>
        <a href="#!" className="modal-close">
          ×
        </a>
      </div>
    </Wrapper>
  )
}
export default GalleryContent
