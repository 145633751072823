import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints';

const Wrapper = styled.div`
  a {
    display: flex;
    width: 250px;
    height: 250px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    opacity: 1;
    position: relative;
    text-decoration: none !important;
    word-break: keep-all;
    white-space: pre;
    img {
      display: block;
      width: 166px;
      height: 116px;
      margin-top: 20px;
      border: solid 3px #646d6a;
      border-radius: 10px;
      opacity: 1;
      overflow: hidden;
      position: relative;
      z-index: 2;
    }
    span {
      width: 100%;
      display: block;
      text-align: center;
      position: relative;
      font-size: 18px;
      z-index: 2;
      font-weight: 600;
    }
    &:before {
      display: block;
      content: '';
      width: 160px;
      height: 160px;
      background: #ebe7d6;
      position: absolute;
      border-radius: 100vh;
      transition: 0.2s;
      z-index: 1;
      opacity: 0;
    }
    &:hover {
      &:before {
        width: 250px;
        height: 250px;
        opacity: 0.5;
      }
    }
  }
    ${BreakPoints.medium} {
    a {
      width: 200px;
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  idName?: string
  /** ボタンラベル */
  label?: string
  /** 画像 */
  fullImg?: string
}

/**
 * ギャラリー用ボタン
 */
const GalleryBtn = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      <a href={props.idName}>
        <img
          src={`/images/official/gallery/${props.fullImg}.png`}
          alt="キンクラ画面"
        />
        <span className="txt-size-26">{props.label}</span>
      </a>
    </Wrapper>
  )
}
export default GalleryBtn
