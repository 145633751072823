import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFaqItems from '../molecules/officialFaqItems'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  background: rgba(172, 216, 213, 0.4);
  position: relative;
  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0;
    margin: 0 auto;
    .p-section-function {
      width: 100%;
      margin: 0 auto 20px;
      text-align: center;
    }
    button {
      display: flex;
      margin: 60px auto 0;
    }
  }
  ${BreakPoints.largest} {
  }
  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0;
    }
  }
`

/**
 * サービスサイトTOPセクション04
 * よくあるご質問
 * @constructor
 */
const OfficialTopSection04 = () => {
  const routeParam = useRouteParam('/service-faq')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <OfficialCommonH3Set label="よくあるご質問" className="anim-fade-up" />
        <div className="p-section-faq anim-fade-up">
          <OfficialFaqItems
            question="社員データを一括で登録したいのですが、可能ですか"
            answer="CSVでのインポートが可能です。大量の社員データを更新する場合もインポートにて一括変更が可能です。"
          />
          <OfficialFaqItems
            question="給与ソフトへの連携は対応していますか"
            answer="はい、対応しております。エクスポートの際、どのデータをエクスポートするのかを選択可能ですので、導入されている給与ソフトに合わせてエクスポートcsvを出力できます。"
          />
          <OfficialFaqItems
            question="試しに使ってみたいのですが、デモサイトはありますか"
            answer={
              '無料トライアルからアカウントを作成していただくと、すぐに無料で利用が可能です。\nユーザー数２０名以下の企業様は永久無料となり、費用発生の心配はございません。ユーザー権限にいくつかの種類がございますので、複数のユーザー様でのご利用をおすすめいたします。'
            }
          />
        </div>
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          質問をもっと見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialTopSection04
