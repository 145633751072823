import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import GalleryBtn from '../atoms/galleryBtn'
import GalleryContent from '../atoms/galleryContent'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialDownloadCta from './officialDownloadCta';

const Wrapper = styled.section`
  width: 100%;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%2C%20.cls-3%20%7B%20fill%3A%20none%3B%20%7D%20.cls-1%20%7B%20stroke%3A%20%230000000a%3B%20%7D%20.cls-2%20%7B%20stroke%3A%20none%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_23613%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2023613%22%20class%3D%22cls-1%22%3E%20%3Crect%20class%3D%22cls-2%22%20width%3D%2212%22%20height%3D%2212%22%2F%3E%20%3Crect%20class%3D%22cls-3%22%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2211%22%20height%3D%2211%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
  .p-section-inner {
    max-width: 1024px;
    padding: 60px 0 80px;
    margin: 0 auto;
    position: relative;
    .p-section-gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-gallery {
      }
    }
  }
`

/**
 * サービスサイト下層特徴3
 * @constructor
 */
const OfficialLowGallery01 = () => (
  <>
    <Wrapper>
      <div className="p-section-inner">
        <OfficialCommonH3Set
          description="クリックで大きな画像をご確認いただけます"
          className="fade-in-auto"
        />
        {/** サムネイルボタン */}
        <div className="p-section-gallery fade-in-auto delay-3">
          <GalleryBtn label="打刻" idName="#modal-01" fullImg="thum01" />
          <GalleryBtn label="自社勤務表" idName="#modal-02" fullImg="thum02" />
          <GalleryBtn
            label="常駐先勤務表"
            idName="#modal-03"
            fullImg="thum03"
          />
          <GalleryBtn label="交通費精算" idName="#modal-04" fullImg="thum04" />
          <GalleryBtn
            label="シフト（PC版）"
            idName="#modal-16"
            fullImg="thum16"
          />
          <GalleryBtn
            label="シフト（アプリ版）"
            idName="#modal-05"
            fullImg="thum05"
          />
          <GalleryBtn label="残業申請" idName="#modal-06" fullImg="thum06" />
          <GalleryBtn
            label="システムアラート"
            idName="#modal-07"
            fullImg="thum07"
          />
          <GalleryBtn
            label="代理申請機能"
            idName="#modal-17"
            fullImg="thum17"
          />
          <GalleryBtn label="承認画面" idName="#modal-08" fullImg="thum08" />
          <GalleryBtn label="データ集計" idName="#modal-09" fullImg="thum09" />
          <GalleryBtn label="社員マスタ" idName="#modal-10" fullImg="thum10" />
          <GalleryBtn label="組織マスタ" idName="#modal-11" fullImg="thum11" />
          <GalleryBtn
            label="承認ルール設定"
            idName="#modal-12"
            fullImg="thum12"
          />
          <GalleryBtn label="36協定設定" idName="#modal-13" fullImg="thum13" />
          <GalleryBtn label="勤務地管理" idName="#modal-14" fullImg="thum14" />
          <GalleryBtn
            label="アラート設定"
            idName="#modal-15"
            fullImg="thum15"
          />
        </div>
      </div>
        <OfficialDownloadCta />
    </Wrapper>

    {/** GalleryBtnに対応したGalleryContentを配置する */}
    <GalleryContent label="打刻" idName="modal-01" fullImg="thum01" />
    <GalleryContent label="自社勤務表" idName="modal-02" fullImg="thum02" />
    <GalleryContent label="常駐先勤務表" idName="modal-03" fullImg="thum03" />
    <GalleryContent label="交通費精算" idName="modal-04" fullImg="thum04" />
    <GalleryContent label="シフト（PC版）" idName="modal-16" fullImg="thum16" />
    <GalleryContent
      label="シフト（アプリ版）"
      idName="modal-05"
      fullImg="thum05"
    />
    <GalleryContent label="残業申請" idName="modal-06" fullImg="thum06" />
    <GalleryContent
      label="システムアラート"
      idName="modal-07"
      fullImg="thum07"
    />
    <GalleryContent label="承認画面" idName="modal-08" fullImg="thum08" />
    <GalleryContent label="データ集計" idName="modal-09" fullImg="thum09" />
    <GalleryContent label="社員マスタ" idName="modal-10" fullImg="thum10" />
    <GalleryContent label="組織マスタ" idName="modal-11" fullImg="thum11" />
    <GalleryContent label="承認ルール設定" idName="modal-12" fullImg="thum12" />
    <GalleryContent label="36協定設定" idName="modal-13" fullImg="thum13" />
    <GalleryContent label="勤務地管理" idName="modal-14" fullImg="thum14" />
    <GalleryContent label="アラート設定" idName="modal-15" fullImg="thum15" />
    <GalleryContent label="代理申請機能" idName="modal-17" fullImg="thum17" />
  </>
)

export default OfficialLowGallery01
